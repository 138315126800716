import { useToast } from 'primevue/usetoast';
import io, { Socket } from 'socket.io-client';

import type { TaskType } from '~/types/tasks';

export enum PairedGameStatus {
  invited,
  accepted,
}

enum WSEvent {
  wantSex = 'want_sex',
  currentPairedGame = 'current_paired_game',
  invitePairedGame = 'invite_paired_game',
  acceptPairedGame = 'accept_paired_game',
  discardPairedGame = 'discard_paired_game',
  sendPartnerTask = 'send_partner_task',
  completePartnerTask = 'complete_partner_task',
}

interface InfoSocketType
  extends Socket<{
    [WSEvent.wantSex]: (event: { value: boolean }) => void;
    [WSEvent.currentPairedGame]: (event: {
      status: PairedGameStatus;
      leaded: boolean;
      task: TaskType | null;
    }) => void;
    [WSEvent.invitePairedGame]: () => void;
    [WSEvent.acceptPairedGame]: () => void;
    [WSEvent.discardPairedGame]: () => void;
    [WSEvent.sendPartnerTask]: (task: TaskType | null) => void;
    [WSEvent.completePartnerTask]: () => void;
  }> {}

const RECONNECT_ON_DEVELOPMENT = false;

export const useInfoStore = defineStore('info', () => {
  const t = useNuxtApp().$i18n.t;
  const toast = useToast();
  const mainStore = useMainStore();

  const initialized = ref(false);
  const socket = ref({} as InfoSocketType);

  const pairedGame = ref<{
    status: PairedGameStatus;
    leaded: boolean;
    task?: TaskType | null;
    isCompleted?: boolean;
  }>();

  const init = () => {
    if (initialized.value || import.meta.server) return;

    socket.value = io(location.origin, {
      reconnection:
        process.env.NODE_ENV === 'production' || RECONNECT_ON_DEVELOPMENT,
    });
    socket.value.on(WSEvent.wantSex, (event) => {
      if (mainStore.user.pairedUser) {
        mainStore.user.pairedUser.wantSex = event.value as boolean;
        if (event.value) {
          // TODO: все уведомления (т.е. по сути UI) не должны быть в сторах
          toast.add({
            severity: 'info',
            summary: t('notifications.want_sex'),
            life: 5000,
          });
          // TODO: подумать лучше об аудио
          // const audio = new Audio('/audio/info-notification.wav');
          // audio.play();
        }
      }
    });

    socket.value.on(WSEvent.invitePairedGame, () => {
      pairedGame.value = {
        status: PairedGameStatus.invited,
        leaded: false,
      };
    });

    socket.value.on(WSEvent.acceptPairedGame, () => {
      toast.add({
        severity: 'success',
        summary: t('notifications.accept_paired_game_received'),
        life: 5000,
      });
      pairedGame.value = {
        status: PairedGameStatus.accepted,
        leaded: true,
      };
    });

    socket.value.on(WSEvent.sendPartnerTask, (event) => {
      if (pairedGame.value) pairedGame.value.task = event;
    });

    socket.value.on(WSEvent.completePartnerTask, () => {
      if (pairedGame.value) {
        pairedGame.value.isCompleted = true;

        nextTick(() => {
          if (pairedGame.value) pairedGame.value.isCompleted = false;
        });
      }
    });

    socket.value.on(WSEvent.currentPairedGame, (event) => {
      pairedGame.value = event;
    });

    socket.value.on(WSEvent.discardPairedGame, () => {
      pairedGame.value = undefined;
      toast.add({
        severity: 'info',
        summary: t('notifications.discard_paired_game_received'),
        life: 5000,
      });
    });

    initialized.value = true;
  };

  const inviteToPairedGame = () => {
    socket.value.emit(WSEvent.invitePairedGame);
    toast.add({
      severity: 'success',
      summary: t('notifications.invite_paired_game_sent'),
      life: 5000,
    });

    pairedGame.value = {
      status: PairedGameStatus.invited,
      leaded: true,
    };
  };

  const acceptPairedGame = () => {
    socket.value.emit(WSEvent.acceptPairedGame);
    toast.add({
      severity: 'success',
      summary: t('notifications.accept_paired_game_sent'),
      life: 5000,
    });

    pairedGame.value = {
      status: PairedGameStatus.accepted,
      leaded: false,
    };
  };

  const discardPairedGame = () => {
    socket.value.emit(WSEvent.discardPairedGame);
    toast.add({
      severity: 'info',
      summary: t('notifications.discard_paired_game_sent'),
      life: 5000,
    });

    pairedGame.value = undefined;
  };

  const sendPartnerTask = (task: TaskType | null) => {
    socket.value.emit(WSEvent.sendPartnerTask, task);
  };

  const completePartnerTask = () => {
    socket.value.emit(WSEvent.completePartnerTask);
  };

  const close = () => {
    if (socket.value instanceof Socket) socket.value.disconnect();
    initialized.value = false;
  };

  return {
    initialized,
    socket,
    pairedGame,

    init,
    close,

    inviteToPairedGame,
    acceptPairedGame,
    discardPairedGame,
    sendPartnerTask,
    completePartnerTask,
  };
});
